import routes from "./routes/Routes.js";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import WalletScreen from "./views/WalletPage.jsx";
import LanguageContextProvider from "./context/languageContext.js";

function App() {
  return (
    <LanguageContextProvider>
    <div className="App">
      <BrowserRouter basename="/">
      <Routes>
            {routes.map(({ path, component, exact }) => (
              <Route key={path} path={path} element={component} exact={exact} />
            ))}
          </Routes>
      </BrowserRouter>
    </div>
    </LanguageContextProvider>
  );
}

export default App;
