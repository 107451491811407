import React, { useState, useEffect, useContext } from "react";
import { ClickSourceContext } from "../context/clickSourceContext";
import Wallet from "../components/Wallet";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import QrScanner from "../components/QRScanner";
import Footer from "../components/Footer";

const WalletPage = ({}) => {

  const [isAddressChecked, setAddressChecked] = useState(false)
  const [walletAddress, setWalletAddress] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   let first2;
  //   if (walletAddress !== null) {
  //     first2 = walletAddress.slice(0, 2);
  //   }
  //   if (first2 === "0x" && walletAddress.length > 25) {
  //     navigate({
  //       pathname: "/wallet",
  //       search: `?address=${walletAddress}`,
  //       replace: true,
  //     });
  //   }
  // }, [walletAddress]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search).get('address');
    if(urlParams !== "null" && urlParams){
      setWalletAddress(urlParams)
      
    }
    setAddressChecked(true)
  }, [])
  


  const goBack = () => {
    navigate("/");
  };

  return (
    <>
      {isAddressChecked && (
        <>
          {!walletAddress ? (
            <QrScanner
              setWalletAddress={(add) => {
                setWalletAddress(add);
              }}
            />
          ) : (
            <Wallet address={walletAddress} navigateBack={goBack} />
          )}
        </>
      )}
      {/* <Footer/> */}
    </>
  );
};

export default WalletPage;
