import React, { useState, useEffect } from "react";
import CardPage from "./CardPage";
import Card from "./Card";
import "./walletStyles.scss";
import {
  getTokenData,
  getMetaData,
  collectionIdByTokenId,
} from "../contracts/contractMethdos/index.js";
import Header from "./Header";
import Footer from "./Footer";
import config from "../config.json";
import { getCollections } from "../contracts/contractMethdos/contractMethdos";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const Wallet = ({ address, isUser }) => {
  const [modalIsShown, setModalIsShown] = useState(false);
  const [cardIsShown, setCardIsShown] = useState(false);
  const [cardId, setCardId] = useState(null);
  const [userCards, setUserCards] = useState([]);
  const [source, setSource] = useState("osszes");

  const [clickedImageUrl, setClickedImageUrl] = useState(null);
  const [clickedTitle, setClickedTitle] = useState(null);
  const [clickedDescription, setClickedDescription] = useState(null);

  const [allCardsShown, setAllCardsShown] = useState(true);
  const [ownedCardsShown, setOwnedCardsShown] = useState(false);
  const [addressShown, setAddressShown] = useState(false);

  const [collections, setCollections] = useState([]);
  const [error, setError] = useState(null);
  const [metaDataUrls, setmetaDataUrls] = useState([]);
  const [mintedCollectionIds, setmintedCollectionIds] = useState([]);
  const [contentLoaded, setcontentLoaded] = useState(false);
  const [cardIsOwned, setCardIsOwned] = useState(false);
  const [collectionWithoutFilter, setcollectionWithoutFilter] = useState([]);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const contract = process.env.REACT_APP_CONTRACT;

  async function tokenData(walletAddress, contractAddress) {
    const tokenUris = await getMetaData(walletAddress, contractAddress);
    return tokenUris;
  }

  async function getMintedCollectionIds(walletAddress, contractAddress) {
    const mintedCollectionIds = await collectionIdByTokenId(
      walletAddress,
      contractAddress
    );
    return mintedCollectionIds;
  }

  useEffect(() => {
    const fetchData = async () => {
      await getCollections()
        .then((response) => {
          //console.log(response, "collections");
          if (response.length > 0) {
            //setCollections(response);
            setcollectionWithoutFilter(response);
          }
        })
        .catch((e) => {
          console.log(e, "error");
          setError(e);
        });

      await tokenData(address, contract)
        .then((response) => {
          //console.log(Object.values(response), "metadata urls");
          const metadataArray = Object.values(response);
          if (metadataArray.length > 0) {
            setmetaDataUrls(metadataArray);
          }
        })
        .catch((e) => {
          console.log(e, "error");
          setError(e);
        });

      await getMintedCollectionIds(address, contract)
        .then((response) => {
          //console.log(response, "user minted from these collection ids");
          if (response.length > 0) {
            setmintedCollectionIds(response);
          }
        })
        .catch((e) => {
          console.log(e, "error");
          setError(e);
        });

      setcontentLoaded(true);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let updatedCollections = collectionWithoutFilter.filter(
      (collection) => !mintedCollectionIds.includes(collection.id)
    );
    //console.log(updatedCollections, "updated collections");
    setCollections(updatedCollections);
  }, [collectionWithoutFilter, mintedCollectionIds]);

  const goToCardPage = (img, title, description) => {
    setClickedImageUrl(img);
    setClickedTitle(title);
    setClickedDescription(description);
    setCardIsOwned(false);
    setCardIsShown(true);
    setSource("osszes");
  };

  const goToCardPageForOwned = (img, title, description) => {
    setClickedImageUrl(img);
    setClickedTitle(title);
    setClickedDescription(description);
    setCardIsOwned(true);
    setCardIsShown(true);
    setSource("sajat");
  };

  const hideCardPage = () => {
    setCardIsShown(false);
  };

  const showAllCards = () => {
    setAllCardsShown(true);
    setOwnedCardsShown(false);
    setAddressShown(false);
    setSource("osszes");
    document.getElementById("osszes").classList.add("selected-tab");
    document.getElementById("sajat").classList.remove("selected-tab");
    document.getElementById("address").classList.remove("selected-tab");
  };

  const showOwnedCards = () => {
    setOwnedCardsShown(true);
    setAllCardsShown(false);
    setAddressShown(false);
    setSource("sajat");
    document.getElementById("sajat").classList.add("selected-tab");
    document.getElementById("osszes").classList.remove("selected-tab");
    document.getElementById("address").classList.remove("selected-tab");
  };

  const showAddress = () => {
    setAddressShown(true);
    setAllCardsShown(false);
    setOwnedCardsShown(false);
    document.getElementById("address").classList.add("selected-tab");
    document.getElementById("sajat").classList.remove("selected-tab");
    document.getElementById("osszes").classList.remove("selected-tab");
  };

  const link = `https://polygonscan.com/address/${address}`;

  const goToScanner = () => {
    navigate("/scanner");
  };

  useEffect(() => {
    //only at the muzeum style needed
    //document.documentElement.style.backgroundColor = "white";
    if (!cardIsShown) {
      document.getElementById("osszes").classList.add("selected-tab");
    }
  }, []);

  useEffect(() => {
    if (!cardIsShown) {
      if (allCardsShown) {
        document.getElementById("osszes").classList.add("selected-tab");
      } else if (ownedCardsShown) {
        document.getElementById("sajat").classList.add("selected-tab");
      }
    }
  }, [cardIsShown]);

  //console.log(window.outerWidth, 'width')

  return (
    <>
      {cardIsShown ? (
        <CardPage
          handleClick={hideCardPage}
          image={clickedImageUrl}
          title={clickedTitle}
          description={clickedDescription}
          isOwned={cardIsOwned}
          source={source}
          address={address}
          ownedCards={metaDataUrls}
          allCards={collections}
        />
      ) : (
        <>
          <div className="wallet">
            <Header />
            <div className="wallet-container">
              <div className="wallet-title">
                <h1
                  style={{
                    color: config.CAMELCODING.titleColor,
                    // WebkitTextStroke: `1px ${config.CAMELCODING.titleColor}`,
                  }}
                >
                  {t("title")}
                </h1>
                <p style={{ color: config.CAMELCODING.textColor }}>
                  {t("walletText")}
                </p>
              </div>
              <div className="tabs-holder">
                <div className="tab" onClick={showAllCards} id="osszes">
                  {t("allTab")}
                </div>
                <div className="tab" onClick={showOwnedCards} id="sajat">
                  {t("myCardsTab")}
                </div>
                <div className="tab" onClick={showAddress} id="address">
                  {t("walletAdrdessTab")}
                </div>
              </div>
              {addressShown && (
                <div
                  className="address"
                  style={{ color: config.CAMELCODING.textColor }}
                >
                  <p className="wallet-address">{t("addressTabText")}</p>
                  <a
                    href={link}
                    target="_blank"
                    className="address-link"
                    style={{ color: config.CAMELCODING.textColor }}
                  >
                    {address}
                  </a>
                </div>
              )}
              {allCardsShown && (
                <div className="card-holder">
                  <>
                    {contentLoaded ? (
                      <>
                          {Object.values(metaDataUrls).map((array, index) => {
                          return array.map((url) => {
                            //console.log(url, "metadata uri");
                            return (
                              <Card
                                key={url}
                                collectionUri={url}
                                onClick={goToCardPageForOwned}
                                isOwned={true}
                              />
                            );
                          });
                        })}
                        {collections.length !== 0 &&
                          collections.map((collection) => {
                            return (
                              <Card
                                key={collection.id}
                                collectionUri={collection.uri}
                                onClick={goToCardPage}
                                isOwned={false}
                              />
                            );
                          })}
                      </>
                    ) : (
                      <div className="loader">
                        <ClipLoader
                          color={"#999999"}
                          loading={!contentLoaded}
                          size={100}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                  </>
                </div>
              )}
              {ownedCardsShown && (
                <div className="card-holder">
                  <>
                    {contentLoaded ? (
                      <>
                        {metaDataUrls.length !== 0 ? (
                          Object.values(metaDataUrls).map((array, index) => {
                            return array.map((url) => {
                              //console.log(url, "metadata uri");
                              return (
                                <Card
                                  key={url}
                                  name={url}
                                  collectionUri={url}
                                  onClick={goToCardPageForOwned}
                                  isOwned={true}
                                />
                              );
                            });
                          })
                        ) : (
                          <div
                            className="no-cards"
                            style={{ color: config.CAMELCODING.textColor }}
                          >
                            Még nem gyűjtöttél egyetlen kártyát sem.
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="loader">
                        <ClipLoader
                          color={"#999999"}
                          loading={!contentLoaded}
                          size={100}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                  </>
                </div>
              )}
            </div>

            {!contentLoaded || window.outerWidth > 425 ? (
              <div className="footer-holder">
                <Footer />
              </div>
            ) : (
              <>
                {metaDataUrls.length === 0 ? (
                  <>
                    {(ownedCardsShown || addressShown) && (
                      <div className="footer-holder">
                        <Footer />
                      </div>
                    )}
                    {allCardsShown && (
                      <div className="footer-holder">
                        <Footer />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {metaDataUrls && metaDataUrls[0].length === 2 ? (
                      <>
                        {ownedCardsShown && (
                          <div
                            className="footer-holder"
                            style={{ marginTop: "40px" }}
                          >
                            <Footer />
                          </div>
                        )}
                        {allCardsShown && (
                          <div className="footer-holder">
                            <Footer />
                          </div>
                        )}
                        {addressShown && (
                          <div className="absolute-footer-holder">
                            <Footer />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {addressShown ? (
                          <div className="absolute-footer-holder">
                            <Footer />
                          </div>
                        ) : (
                          <div className="footer-holder">
                            <Footer />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>

          {!cardIsShown && (
            <div className="sticky-button-holder">
              <button
                className="wallet-scanner-button"
                style={{ color: config.CAMELCODING.textColor }}
                onClick={goToScanner}
              >
                {t("scanButtonText")}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Wallet;
