import "./Home.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Header from "../components/Header";
import config from "../config.json";
import { LanguageContext } from "../context/languageContext";
import { useTranslation } from 'react-i18next';
import Footer from "../components/Footer";

const Home = () => {
  const [manualAddressInput, setManualAddressInput] = useState(false);
  const [address, setAddress] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const navigate = useNavigate();
  
  const langCtx = useContext(LanguageContext);

  const { t } = useTranslation();

  const navigatToQRScan = () => {
    navigate("/wallet");
  };
  const handleManualAddress = () => {
    setManualAddressInput(true);
  };
  const showNFTs = () => {
    let first2;
    if (address !== null) {
      first2 = address.slice(0, 2);
    }
    if (first2 === "0x" && address.length > 25) {
      navigate("/wallet?address=" + address);
    } else {
      setAddressError("Invalid address");
    }
  };

  useEffect(() => {
    let first2;
        if(address !== null){
            first2 = address.slice(0, 2);
        }
        if(first2 === "0x" && address.length == 42){
            navigate("/wallet?address=" +  address)
        }else{
            setAddressError("Invalid address")
        }
  }, [address])
  

  const handleInputChange = (event) => {
    const address = event.target.value;
    setAddress(address);
    //navigate("/wallet?address=" +  address);
  };

  return (
    <>
      <Header />
      <div className="lb-home">
        <div className="lb-content-section">
          <h2 className="page-title-home" style={{ color: config.CAMELCODING.titleColor}}>{t("title")}</h2>
          <div className="home-texts" style={{ color: config.CAMELCODING.textColor }}>
        {t("welcomeText")}
          </div>
          <div className="scan-button">
            <p style={{ color: config.CAMELCODING.textColor }}> {t("scanText")}</p>
            <button
              className="gotoscan-button uppercase"
              onClick={navigatToQRScan}
            >
             {t("scanButtonText")}
            </button>
          </div>

          <div className="manual-input">
            <p style={{ color: config.CAMELCODING.textColor }}>
            {t("addressInputText")}
            </p>
            <input
              className="address-input"
              onChange={handleInputChange}
              type="text"
              placeholder={t("addressInputPlaceholder")}
            />
          </div>
        </div>

        <div className="langpage-footer">
        <Footer />
      </div>
      </div>
      
    </>
  );
};

export default Home;
