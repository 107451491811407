import React, {useState, useEffect} from "react";
import cardImg from "../assets/images/nft.png";
import axios from "axios";
import config from "../config.json";

const Card = ({onClick, collectionUri, isOwned}) => {

  //console.log(collectionUri, 'collection uri')

    const [title, setTitle] = useState("a kártya neve/címe");
    const [description, setDescription] = useState("Description")
    const [image, setImg] = useState("")

    useEffect(() => {
      const getMetadata = async() =>{
        await axios.get(collectionUri).then((response)=>{
          if(response.data){
            //console.log(response.data)
            setTitle(response.data.name);
            setDescription(response.data.description);
            setImg(response.data.image);
          }
        }).catch((e) => {
          console.log(e, "error");
        });
      }
      getMetadata();
    }, [])

  return (
    <>
      <div className="card">
        <img className={isOwned === true ? "card-image" : "not-owned-card-image"}
          src={image}
          onClick={() => onClick(image, title, description)}
        />
        <h5 className="card-title" style={{color: config.CAMELCODING.titleColor}}>{title}</h5>
      </div>
    </>
  );
};

export default Card;