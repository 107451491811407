import React, { useContext, useEffect, useState } from "react";
import card from "../assets/images/nft.png";
import logout from "../assets/images/x-circle.png";
import errorIcon from "../assets/images/error-icon.png";
import { useTranslation } from "react-i18next";
import { ClickSourceContext } from "../context/clickSourceContext";
import { useLocation, useNavigate } from "react-router-dom";
import left from "../assets/images/left.png";
import right from "../assets/images/right.png";
import Carousel from "./Carousel";

const CardPage = ({
  handleClick,
  image,
  description,
  title,
  isOwned,
  address,
  ownedCards,
  allCards,
}) => {
  const { t } = useTranslation();

  const ctx = useContext(ClickSourceContext);
  const navigate = useNavigate();

  const [walletAddress, setwalletAddress] = useState(address);
  //only at muzeum styles needed
  // useEffect(() => {
  //   document.documentElement.style.backgroundColor = "#5A513F";
  // }, []);

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });
  }

  var textWithLinks = urlify(description);

  useEffect(() => {
    window.history.pushState(
      null,
      null,
      `${window.location.pathname}?address=${walletAddress}`
    );
    window.addEventListener("popstate", handleClick);

    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener("popstate", handleClick);
    };
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* {ownedCards.length === 0 || isOwned === false ? (
        <div className="single-card">
          <div className="title-button">
            <h1 className="cardpage-title">{title}</h1>
            <img src={logout} className="close-btn" onClick={handleClick} />
          </div>
          {!isOwned && (
            <div className="notOwned">
              <img src={errorIcon}></img>
              <p className="notOwned-text">{t("warningText")}</p>
            </div>
          )}
          <div className="card-description">
            <img src={image} style={{ cursor: "pointer" }}></img>
            <p
              className="cardpage-desc"
              dangerouslySetInnerHTML={{ __html: textWithLinks }}
            ></p>
          </div>
        </div>
      ) : (
        <div className="single-card">
          <Carousel
            data={ownedCards}
            actualImage={image}
            actualDescrption={description}
            handleClick={handleClick}
            actualTitle={title}
          />
        </div>
      )} */}
      <div className="single-card">
        <Carousel
          ownedCards={ownedCards}
          actualImage={image}
          actualDescrption={description}
          handleClick={handleClick}
          actualTitle={title}
          notOwnedCards={allCards}
          isOwned={isOwned}
        />
      </div>
    </>
  );
};

export default CardPage;
