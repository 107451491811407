import React, { useState, useEffect, useContext } from "react";
import QrReader from "react-qr-reader";
import "./QRScanner.scss";
import { useNavigate } from "react-router-dom";
import closeBtn from "../assets/images/x-circle.png";
import { ClickSourceContext } from "../context/clickSourceContext";

const QrScanner = ({ setWalletAddress }) => {
  const [showDialog, setDiaglog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const sourceCtx = useContext(ClickSourceContext);


  const handleScan = (scanData) => {
    if (scanData && scanData) {
      //console.log(scanData, 'scanned data')
      const regexForUrl = /^((.*\?.*address=)|(ether:))?(0x[a-fA-F0-9]{40})/;
      const checkedUrl = scanData.match(regexForUrl)[0];
      //console.log(checkedUrl, 'checked url')
      const regexForAddress = /0x[a-fA-F0-9]{40}/
      const extractedAddress = checkedUrl.match(regexForAddress)[0];
      //console.log(extractedAddress, 'extracetd address')

      const address = extractedAddress.toLowerCase();
      setWalletAddress(address);
      sourceCtx.setScannedAddress(address);
      navigate(`/wallet?address=${address}`);
    }
  };

  const handleNavigate = () => {
    navigate("/home");
  };

  const onError = () => {
    console.log("scanner error");
  };

  return (
    <>
      <div className="scanner-holder">
        <button className="qr-close-btn" onClick={handleNavigate}>
          <img src={closeBtn} />
        </button>
        {!showDialog && !processing && (
          <QrReader
            delay={500}
            onScan={handleScan}
            constraints={{
              facingMode: "environment",
            }}
            className="qr-reader"
            onError={onError}
          />
        )}
      </div>
    </>
  );
};

export default QrScanner;
