import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import logout from "../assets/images/x-circle.png";
import errorIcon from "../assets/images/error-icon.png";
import { useTranslation } from "react-i18next";

const Carousel = ({
  ownedCards,
  handleClick,
  actualImage,
  actualDescrption,
  actualTitle,
  notOwnedCards,
  isOwned
}) => {
  const [metadatas, setMetadatas] = useState([]);
  const [filteredMetadatas, setFilteredMetadatas] = useState([]);
  const [userOwns, setUserOwns] = useState(isOwned);

  const [ownedCardsArray, setOwnedCardsArray] = useState([]);
  const [notOwnedCardsArray, setNotOwnedCardsArray] = useState([]);

  const { t } = useTranslation();

  //console.log(allCards, 'all cards')
  //console.log(isOwned, ' is owned')
  //console.log(userOwns, 'user owns');
  //console.log(ownedCards, 'owned cards');
  //console.log(notOwnedCards, 'not owned cards')

  useEffect(() => {
    const fetchMetadata = async () => {
      const promises = Object.values(notOwnedCards).map(async (url) => {
        //console.log(url.uri, 'allcards values')
        try {
          const response = await axios.get(url.uri);
          if (response.data) {
            //console.log(response.data, 'resp data')
            return {
              title: response.data.name,
              description: response.data.description,
              image: response.data.image,
              owned: false
            };
          }
        } catch (error) {
          console.log(error, "error");
        }
        // Return a default value if response data is not available
        return {
          title: "",
          description: "",
          image: "",
        };
      });

      const metadataList = await Promise.all(promises);
      //console.log(metadataList, 'metadata list not owned cards');
      setNotOwnedCardsArray(metadataList);
      //setMetadatas(metadataList);
    };

    fetchMetadata();
  }, [notOwnedCards]);

  
  useEffect(() => {
    const fetchMetadata = async () => {
      if(ownedCards.length !== 0){
        const promises = ownedCards[0].map(async (url) => {
          //console.log(url.uri, 'allcards values')
          try {
            const response = await axios.get(url);
            if (response.data) {
              //console.log(response.data, 'resp data')
              return {
                title: response.data.name,
                description: response.data.description,
                image: response.data.image,
                owned:true
              };
            }
          } catch (error) {
            console.log(error, "error");
          }
          // Return a default value if response data is not available
          return {
            title: "",
            description: "",
            image: "",
          };
        });
  
        const metadataList = await Promise.all(promises);
        //console.log(metadataList, 'metadata list owned cards');
        setOwnedCardsArray(metadataList);
      }

      //etMetadatas(metadataList);
    };

    fetchMetadata();
  }, [ownedCards]);

  useEffect(() => {
    const metas = [...ownedCardsArray, ...notOwnedCardsArray];
    //console.log(metas, 'metas')
    let filteredMetadatas = metas.filter(
      (metadata) => metadata.image !== actualImage
    );
    //console.log(filteredMetadatas, 'filtered metas')
    setFilteredMetadatas(filteredMetadatas);
  }, [ownedCardsArray, notOwnedCardsArray]);

  var settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });
  }

  return (
    <>
      <Slider {...settings}>
        <div className="single-card">
          <div className="title-button">
            <h1 className="cardpage-title">{actualTitle}</h1>
            <img src={logout} className="close-btn" onClick={handleClick} />
          </div>
          {!isOwned && (
            <div className="notOwned">
              <img src={errorIcon}></img>
              <p className="notOwned-text">{t("warningText")}</p>
            </div>
          )}
          <div className="card-description">
            <img src={actualImage} style={{ cursor: "pointer" }}></img>
            <p
              className="cardpage-desc"
              dangerouslySetInnerHTML={{
                __html: urlify(actualDescrption),
              }}
            ></p>
          </div>
        </div>
        {filteredMetadatas.map((metadata, index) => {
          return (
            <div className="single-card" key={index}>
              <div className="title-button">
                <h1 className="cardpage-title">{metadata.title}</h1>
                <img src={logout} className="close-btn" onClick={handleClick} />
              </div>
              {metadata.owned === false ? (
            <div className="notOwned">
              <img src={errorIcon}></img>
              <p className="notOwned-text">{t("warningText")}</p>
            </div>
          ) : (<></>)}
              <div className="card-description" key={index}>
                <img src={metadata.image} style={{ cursor: "pointer" }}></img>
                <p
                  className="cardpage-desc"
                  dangerouslySetInnerHTML={{
                    __html: urlify(metadata.description),
                  }}
                ></p>
              </div>
            </div>
            
          );
        })}
      </Slider>
    </>
  );
};

export default Carousel;
